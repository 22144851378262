/*******************  DEFAULT VARIABLES ***************/
$defaultTexteColor: #0b1b45;
$defaultLinkColor: #f19668;
$defaultLinkColorHover: #40bdfa;
$defaultBgcolor: #FFF;
$defaultFontSize: 1.6em;


$regular: 'avenir_nextregular';
$bold: 'avenir_nextbold';
$italic: 'avenir_nextitalic';

/*******************  SITE VARIABLES ******************/
$wrap: 1200px;
$marge: calc((100% - #{$wrap}) / 2);

$color-main: #0b1b45;
$color-second: #f19668;
$color-third: #40bdfa;


$white: #ffffff;
$black: #000000;
$grey: #f3f3f8;
$grey-clair: #E2E2E9;
$blue: #0a2757;
