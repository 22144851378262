@import "~swiper/dist/idangerous.swiper.css";

@import "elements/utils";
@import "elements/fonts";
@import "elements/variables";
@import "elements/normalize";
@import "elements/hover";

@import "pages/commun";
@import "pages/front";
@import "pages/bu";
@import "pages/news";
@import "pages/map";
