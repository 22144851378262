.blog, .single {
  section {
    h1 {
      color: $color-third;
      &::before {
        background-color: $color-third;
      }
    }
    .breadcrumb {
      a {
        color: $color-third;
        &.current-item {
          color: $color-main;
        }
      }
    }
    h2 {
      color: $color-main;
      &::before {
        background-color: $color-third;
      }
      span {
        color: $color-third;
      }
    }
    h3 {
      color: $color-third;
      margin: 0;
      &::before {
        display: none;
      }
    }
    p {
      color: $color-main;
    }
    blockquote p {
      color: $color-third;
    }
    .b-grey {
      background-color: $grey;
    }
    a.more {
      @extend .bt-more;
      border: none;
      width: 25%;
      margin-left: 25%;
      @media only screen and (max-width: 1023px) {
        width: 50%;
      }
      @media only screen and (max-width: 600px) {
        width: 100%;
        margin-left: 0;
        margin-bottom: 140px;
      }
    }

    .b-news {
      margin: 80px 0;
      display: block;
      position: relative;
      .cat {
        position: absolute;
        top: -10px;
        left: 0;
        text-transform: uppercase;
        display: block;
        color: $color-main;
        font-family: $bold;
        font-size: 2.8rem;
        z-index: -1;
        @media only screen and (max-width: 600px) {
          font-size: 2.0rem;
          top: 0px;
        }
      }
      .data {
        padding-top: 16px;
        font-size: 1.5rem;
        display: table;
        @media only screen and (max-width: 600px) {
          display: block;
        }

        figure, div {
          display: table-cell;
          vertical-align: top;
          width: 50%;
          position: relative;
          @media only screen and (max-width: 600px) {
            width: 100%;
            display: block;
            padding: 0;
            margin-bottom: 10px;
          }
        }
        figure {
          svg {
            position: absolute;
            font-size: 3.0rem;
            color: #40bdfa;
            right: 0;
            top: 10%;
            opacity: 1;
            transition: all 0.3s ease;
            transform: rotate(180deg);
            z-index: 10;
          }
          figcaption {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            background-color: #40bdfa;
            transition: all 0.3s ease;
          }
        }
        div {
          padding: 0 0 0 10px;
          @media only screen and (max-width: 600px) {
            padding: 0;
          }
        }
        .more {
          position: absolute;
          left: 10px;
          bottom: 0;
          width: 100%;
          display: block;
          text-align: right;
          font-size: 1.4rem;
          line-height: 0;
          font-family: $bold;
          padding-right: 3%;
          color: $color-main;
          @media only screen and (max-width: 600px) {
            position: relative;
            left: inherit;
            padding: 5px 0;
          }
          &::before {
            content: '';
            position: absolute;
            width: 70%;
            left: 0;
            bottom: 0;
            height: 1px;
            background-color: $color-main;
          }
        }
      }

      &:nth-of-type(even) {
        .cat {
          right: 0;
          left: inherit;
        }
        .data {
          figure {
            svg {
              right: inherit;
              left: 0;
              transform: rotate(0deg);
            }
          }
          div {
            padding: 0 10px 0 0;
          }
        }
      }
    }
    .filtres {
      text-align: right;
      padding: 0 16.667%;
      position: relative;
      z-index: 20;
      p {
        display: inline-block;
        text-align: left;
        width: 50%;
        background-color: $color-third;
        box-shadow: 0 2px 12px 0 rgba($color-third, 0.52);
        color: $white;
        font-family: $bold;
        padding: 5px 8px;
        position: relative;
        cursor: pointer;
        svg {
          position: absolute;
          right: 10px;
          top: 50%;
          transition: all 0.3s ease;
          transform: translateY(-50%) rotate(180deg);
        }
        &.actif {
          svg {
            transform: translateY(-50%) rotate(0deg);
          }
        }
      }
      ul {
        display: none;
        position: absolute;
        right: 16.667%;
        top: 36px;
        width: 33.33%;
        text-align: left;
        background-color: $white;
        padding: 5px 0;
        box-shadow: 0 8px 20px 0 rgba($black, 0.05);
        li {
          list-style: none;
          padding: 5px 10px;
          a {
            display: block;
            color: $color-main;
          }
        }
      }
      @media only screen and (max-width: 600px) {
        padding: 0;
        p {
          width: 100%;
        }
      }
    }

    .b-contenu {
      padding: 40px 16.667% 120px 16.667%;
      @media only screen and (max-width: 600px) {
        padding: 40px 0;
      }
    }

    .b-newsletter {
      width: 66.668%;
      left: 16.667%;
      bottom: -20px;
      @media only screen and (max-width: 600px) {
        width: 100%;
        left: 0;
      }
    }

    .b-contact {
      padding-top: 80px;
      a {
        color: $white;
      }
    }

    .title {
      padding: 80px 16.667% 0 16.667%;
      position: relative;
      z-index: 10;
      @media only screen and (max-width: 1023px) {
        padding: 80px 0 0 0;
      }
      div, h1 {
        display: inline-block;
        vertical-align: middle;
        width: 50%;
        position: relative;
        @media only screen and (max-width: 1023px) {
          display: block;
          width: 100%;
        }
      }
      div {
        background-color: $color-main;
        color: rgba($white, 0.5);
        padding: 5%;
        font-size: 7.0rem;
        text-align: center;
        font-family: $bold;
        span {
          position: absolute;
          text-transform: uppercase;
          &.cat {
            display: block;
            left: 0;
            top: -24px;
            font-size: 2.8rem;
            color: $color-main;
          }
          &.month {
            left: 50%;
            top: 70%;
            transform: translate(-50%, -70%);
            color: $white;
            font-size: 2.0rem;
            letter-spacing: 2px;
            font-family: $regular;
          }
        }
      }
      h1 {
        width: 49%;
        padding: 0 2% 0 15%;
        font-size: 1.8rem;
        @media only screen and (max-width: 1023px) {
          display: block;
          width: 100%;
          padding: 10px 0;
          &::before {
            display: none;
          }
        }
        &::before {
          top: 50%;
          transform: translateY(-50%);
          width: 45%;
          left: -15%;
          height: 2px;
        }
      }
    }

    .diapo-product {
      .swiper-slide {
        figure {
          width: 100%;
          figcaption {
            color: $color-third;
            &::after {
              background-color: $color-third;
              width: 10%;
            }
          }
        }
      }
      .page-prev, .page-next {
        color: $color-third;
      }
      .pager {
        background-color: $white;
        li {
          color: $color-third;
          &.actif {
            color: $color-main;
          }
        }
      }
    }

    .b-video {
      padding-bottom: 0;
    }
  }
}

.single {
  section {
    .b-contenu {
      padding-bottom: 0;
    }
    .b-bicolor {
      background: linear-gradient(to right, $color-third 0%, $color-third 44%, $white 44%, $white 100%);
      position: relative;
      @media only screen and (max-width: 1023px) {
        background: none;
      }
      .fd-grey {
        position: absolute;
        content: '';
        width: 100%;
        background: linear-gradient(to right, transparent 0%, transparent 44%, $grey 44%, $grey 100%);
        height: 181px;
        top: 0;
        left: 0;
      }
      &.only {
        background: none;
        padding-top: 40px;
        .col {
          width: 100%;
          display: block;
          padding: 0 16.66%;
          background: none;
          @media only screen and (max-width: 1023px) {
            padding: 0;
          }
          .b-share {
            width: 100%;
          }
        }
      }
    }
    .col {
      position: relative;
      z-index: 10;
      padding: 0;
      @media only screen and (max-width: 1023px) {
        margin-bottom: 0;
        width: 100%;
        display: table;
      }

      .b-news {
        margin: 40px 0;
        .data {
          div {
            padding: 0;
            .more {
              width: 95%;
              padding-rigth: 5%;
              &::before {
                width: 45%;
              }
            }
          }
          h3 {
            color: $white;
          }
          svg {
            left: 0;
            right: inherit;
            transform: rotate(0deg);
          }
        }
        &:nth-of-type(even) {
          .data {
            svg {
              right: 0;
              left: inherit;
              transform: rotate(180deg);
            }
          }
        }
      }
      .pagination {
        padding: 40px 33.33% 80px 0;
        font-size: 0;
        background-color: $grey;
        position: relative;
        @media only screen and (max-width: 1023px) {
          text-align: center;
          padding: 40px 0;
        }

        li {
          list-style-type: none;
          display: inline-block;
          vertical-align: middle;
          width: 50%;
          font-size: 1.4rem;
          font-family: $bold;
          text-align: left;
          position: relative;
          @media only screen and (max-width: 1023px) {
            text-align: center;
          }

          svg {
            display: inline-block;
            padding: 0 10px;
            font-size: 1.2rem;
          }
          a {
            display: block;
            color: $color-third;
          }
          &:nth-of-type(even) {
            text-align: right;
            @media only screen and (max-width: 1023px) {
              text-align: center;
            }
          }
        }
      }
      .b-share {
        width: 66.66%;
        background-color: $color-main;
        color: $white;
        text-align: right;
        position: relative;
        @media only screen and (max-width: 1023px) {
          width: 100%;
          text-align: center;
        }
        p, ul {
          display: inline-block;
          vertical-align: middle;
          color: $white;
          font-family: $bold;
          @media only screen and (max-width: 600px) {
            display: block;
          }
        }
        ul {
          margin: 5px 10px 0 40px;
          @media only screen and (max-width: 600px) {
            margin: 5px 0;
          }
          li {
            display: inline-block;
            a {
              display: block;
              padding: 5px 15px;
              color: $white;
            }
          }
        }

      }
    }
    .col:nth-of-type(1) {
      background-color: $color-third;
      &::before, &::after {
        display: none;
      }

      .title {
        text-transform: uppercase;
        padding: 0;
        text-align: right;
        font-size: 1.8rem;
        position: relative;
        padding-left: 36%;
        margin: 40px 0 0 0;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          height: 1px;
          background-color: $color-main;
          width: 36%;
          transform: translateY(-50%);
        }
      }
    }
    .b-contact {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      @media only screen and (max-width: 1023px) {
        position: relative;
        padding: 20px 0;
        a {
          width: 100%;
        }
      }
    }
  }
}
