/* Style generique */
.d-none {
  display: none !important;
}

.mobile-view {
  display: none;
  @media only screen and (max-width: 1023px) {
    display: block;
  }
}

.desktop-view {
  display: block;
  @media only screen and (max-width: 1023px) {
    display: none;
  }
}

.titre1 {
  font-family: $regular;
  text-transform: uppercase;
  font-size: 2.4rem;
  letter-spacing: 0.8px;
  color: $color-second;
  position: relative;
  padding: 30px 0 10px 5px;
  @media only screen and (max-width: 600px) {
    font-size: 2.0rem;
  }

  &::before {
    content: '';
    position: absolute;
    width: 80px;
    height: 1px;
    top: 45px;
    background-color: $color-second;
    left: -100px;
  }
}

.titre2 {
  text-transform: uppercase;
  font-size: 3.0rem;
  font-family: $regular;
  color: $color-main;
  position: relative;
  letter-spacing: 1px;
  margin: 20px 0 50px 0;
  @media only screen and (max-width: 600px) {
    font-size: 2.25rem;
  }

  &::before {
    content: '';
    position: absolute;
    width: 60px;
    height: 1px;
    top: 20px;
    background-color: $color-second;
    left: -80px;
  }

  span {
    display: block;
    font-family: $bold;
    color: $color-second;
    font-size: 1.8rem;
    letter-spacing: 0.4px;
    text-transform: none;
    line-height: 19px;

  }
}

.titre3 {
  color: $color-second;
  font-size: 1.8rem;
  letter-spacing: 0.4px;
  margin: 30px 0;
  position: relative;
  @media only screen and (max-width: 600px) {
    font-size: 1.6rem;
  }

  &::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 1px;
    top: 50%;
    transform: translateY(-50%);
    background-color: $color-second;
    left: -80px;
  }
}

a.more {
  display: inline-block;
  border: 1px solid $white;
  padding: 5px 30px;
  font-family: $bold;
  font-size: 1.7rem;
  letter-spacing: 0.2px;
  color: $white;
  @media only screen and (max-width: 600px) {
    font-size: 1.3rem;
    display: block;
    text-align: center;
    padding: 5px;
  }
}

a {
  &.link-pdf {
    display: inline-block;
    padding: 5px 5px 5px 40px;
    background: url("../../images/icon-pdf.png") no-repeat;
    background-size: contain;
  }
}

p.visite {
  cursor: pointer;
  display: block;
  color: $color-second;
  font-size: 1.6rem;
  font-family: $bold;
  text-decoration: underline;
  position: relative;
  @media only screen and (max-width: 767px) {
    text-align: center;
  }

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 200px;
    height: 150px;
    background: $color-second;
    margin: 0 100px 0 0;
    background: url("../../images/icon-visite.png") center center no-repeat;

    @media only screen and (max-width: 767px) {
      margin: 0 0 20px 0;
      width: 100%;
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 200px;
    top: 50%;
    height: 1px;
    width: 75px;
    background: $color-second;

    @media only screen and (max-width: 767px) {
      content: none;
    }
  }
}

.bt-more {
  display: block;
  text-align: center;
  font-family: $bold;
  color: $white;
  font-size: 1.4rem;
  background-color: $color-main;
  box-shadow: 0 4px 12px 0 rgba($color-main, 0.41);
  padding: 10px;
  transition: all 0.3s ease;
  margin: 20px 0;
}

.more-filaire {
  display: block;
  position: relative;
  color: $color-second;
  text-align: right;
  font-size: 1.4rem;
  border: none;
  padding: 5px 0;
  background-color: $white;

  &::before {
    content: '';
    position: absolute;
    z-index: 10;
    height: 1px;
    width: 100%;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    background-color: $color-second;
  }

  span {
    display: inline-block;
    background-color: $white;
    position: relative;
    z-index: 20;
    padding-left: 10px;
  }
}

.temoignages {
  padding: 2.5% 2%;
  position: relative;

  span {
    font-family: Arial;
    font-size: 2.5rem;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
  }

  p {
    line-height: 1.29;
    font-family: $bold;
  }

  .auteurs {
    font-family: $italic;
    font-size: 1.2rem;
    position: relative;
    margin: 15px 0 0 0;
    padding-top: 15px;

    &::before {
      content: '';
      position: absolute;
      height: 2px;
      background-color: $white;
      width: 30%;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
  }
}

.b-ligne {
  display: block;
  @media only screen and (max-width: 600px) {
    box-shadow: 0 2px 12px 0 rgba(178, 178, 178, 0.3);
  }

  .data {
    figure {
      img {
        width: 100%;
        height: auto;
      }

      figcaption {
        background-color: $color-second;
        color: $white;
        text-align: center;
        text-transform: uppercase;

        span {
          position: absolute;
          top: 70%;
          left: 50%;
          transform: translate(-50%, -70%);
          font-size: 2.0rem;

          &:first-child {
            font-size: 7.0rem;
            font-family: $bold;
            color: rgba($white, 0.6);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    div {
      vertical-align: middle;

      svg, h3 {
        display: inline-block;
        vertical-align: middle;
      }

      svg {
        color: $color-second;
        width: 38%;
        height: 60px;
        text-align: center;
      }

      h3 {
        border-bottom: 1px solid $color-second;
        width: 60%;
      }
    }
  }
}

.bloc-more {
  display: none;
  padding-bottom: 40px;
}

.center-wrap {
  width: 100%;
  //max-width: $wrap;
  margin: 0 auto;
  position: relative;
  font-size: 0;
  z-index: 10;

  & > div {
    font-size: 1.6rem;
  }

  &.ss-index {
    z-index: 1;
  }
}

.grille {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  span {
    position: absolute;
    z-index: 5;
    height: 100%;
    top: 0;
    width: 1px;
    left: 0;
    background-color: rgba($grey-clair, 0.4);

    &:nth-child(1) {
      background-color: rgba($color-second, 0.2);
    }

    &:nth-child(2) {
      left: 16.66%;
      background-color: rgba($color-second, 0.2);
    }

    &:nth-child(3) {
      left: 33.33%;
      background-color: rgba($color-second, 0.2);
    }

    &:nth-child(4) {
      left: 50%;
    }

    &:nth-child(5) {
      left: 66.66%;
    }

    &:nth-child(6) {
      left: 83.33%;
    }

    &:nth-child(7) {
      left: 100%;
    }

  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.twitter-tweet {
  margin-left: auto;
  margin-right: auto;
}

/* HEADER */
.header {
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(178, 178, 178, 0.3);
  text-align: right;
  position: fixed;
  width: 100%;
  z-index: 100;

  &.openMenu {
    height: 100%;
  }

  a {
    color: $color-main;
  }

  .logo {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5%;
    width: 8%;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

  }

  .menu-mobile {
    display: none;
    padding: 10px 30px;
  }

  nav {
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    width: 75%;
    font-size: 1.4rem;
    letter-spacing: 0.5px;

    ul {
      display: table;
      width: 100%;
    }

    li {
      display: table-cell;
      vertical-align: middle;
      text-transform: uppercase;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 4px;
        background-color: $color-second;
        transition: all 0.3s ease;
      }

      &.actif {
        &::after {
          width: 100%;
        }
      }

      a {
        display: block;
        padding: 20px 10px;
      }

      ul {
        display: none;
        position: absolute;
        z-index: 20;
        top: 100%;
        background-color: $white;
        padding: 5%;
        width: auto;
        min-width: 100%;
        box-shadow: 0 2px 12px 0 rgba(178, 178, 178, 0.3);

        li {
          display: block;
          text-transform: none;
          text-align: left;
          margin: 10px 0;

          &::after {
            display: none;
          }

          a {
            padding: 5px 0;
          }
        }
      }

      &.news {
        &::before {
          content: '';
          position: absolute;
          z-index: 5;
          width: 100%;
          left: 50%;
          transform: translateX(-50%);
          height: 100%;
          top: 0;
          background-color: $color-third;
          box-shadow: 0 2px 11px 0 $color-third;
        }

        a {
          position: relative;
          z-index: 10;
          color: $white;
          font-family: $bold;
        }

        &::after {
          display: none;
        }
      }

      &.contact {
        a {
          position: relative;
          font-size: 0;
          padding: 5px 20px;

          &::before {
            font-family: 'latecoere';
            content: "";
            position: absolute;
            font-size: 1.0rem;
            top: 50%;
            left: 99%;
            transform: translate(-50%, -50%);
            background-image: url("../../images/pictos/mail.svg");
            width: 100%;
            height: 20px;
            background-repeat: no-repeat;
          }
        }

        &::after {
          display: none;
        }

        &.actif {
          a {
            &::before {
              color: $color-second;
            }
          }
        }
      }
    }
  }

  .languages {
    display: inline-block;
    vertical-align: middle;

    li {
      display: inline-block;
    }
  }

  @media only screen and (max-width: 1023px) {
    position: relative;
    nav, .languages {
      display: none;
      width: 100%;
    }
    nav, .languages {
      padding: 10px 0;

      ul {
        display: block;
      }

      li {
        display: block;
        text-align: center;
        border-bottom: 1px solid $grey;

        &.contact a {
          width: 100%;
          padding: 20px 0;
        }

        ul {
          background-color: $color-main;
          position: relative;
          width: 100%;
          top: inherit;
          left: inherit;

          li a {
            color: $white;
          }
        }
      }
    }
    .menu-mobile {
      display: inline-block;
    }
    .logo {
      position: relative;
      top: inherit;
      left: inherit;
      float: left;
      transform: none;
      padding: 5px 30px 0 30px;
      width: auto;
    }
  }
  @media only screen and (max-width: 600px) {
    .menu-mobile {
      padding: 10px 15px;
    }
    .logo {
      padding: 5px 15px 0 15px;
    }
  }
}

/* SECTION */

section {
  padding: 58px 30px 0 30px;
  min-height: calc(100vh - 79px);

  .breadcrumb {
    position: relative;
    z-index: 10;
    margin-left: 16.66%;
  }

  @media only screen and (max-width: 1023px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 600px) {
    padding: 0 15px;
  }

  h1 {
    @extend .titre1;
    margin-left: 16.00%;
  }

  h2 {
    @extend .titre2;
  }

  h3 {
    @extend .titre3;
  }

  p {
    padding: 5px 0;
    line-height: 26px;
  }

  blockquote {
    color: $color-third;
    display: block;
    padding: 5px 0;
    font-family: $italic;
    margin: 10px 20px;

    p {
      color: $color-third;
    }
  }

  .breadcrumb {
    font-family: $bold;
    font-size: 1.3rem;
    padding-left: 5px;
    padding-bottom: 20px;

    a.current-item {
      color: $color-main;
    }
  }

  form {
    input, select, textarea {
      border: 1px solid $color-main;
      padding: 5px;
      width: 50%;
      color: $color-main;
      font-family: $regular;
      outline: none;
      appearance: none;
      border-radius: 0;

      &::placeholder {
        color: $color-main;
      }

      @media only screen and (max-width: 1023px) {
        width: 100%;
      }
    }
    input[type="checkbox"] {
      appearance: checkbox;
      cursor: pointer;
      width: fit-content;
    }

    p {
      padding: 10px 0;

      &.error {
        color: $color-second;
      }

      &.requis {
        padding: 0;
        font-size: 1.0rem;
      }

      &.submit {
        padding: 10px 50% 10px 25%;
        @media only screen and (max-width: 1023px) {
          padding: 10px 25%;
        }
        @media only screen and (max-width: 600px) {
          padding: 10px 0;
        }

        input {
          background-color: $color-main;
          box-shadow: 0 4px 12px 0 rgba($color-main, 0.41);
          width: 100%;
          color: $white;
          font-family: $bold;
        }
      }
    }

  }

  .col {
    vertical-align: top;
    display: inline-block;
    width: 50%;
    padding: 30px 0;
    position: relative;
    margin-bottom: 60px;

    & > * {
      position: relative;
      z-index: 10;
    }

    h2 {
      margin-left: -5px;
      margin-bottom: 30px;
    }

    .data {
      display: table;
      width: 100%;
      position: relative;
      @media only screen and (max-width: 600px) {
        display: block;
      }

      div, figure {
        display: table-cell;
        width: 50%;
        vertical-align: top;
        position: relative;

        @media only screen and (max-width: 600px) {
          display: block;
          width: 100%;
          margin-bottom: 10px;
        }
      }

      figure {
        overflow: hidden;

        img {
          display: block;
          height: auto;
          width: 100%;
        }
      }

      h3 {
        font-family: $regular;
        text-transform: uppercase;

        &::before {
          display: none;
        }
      }

    }

    .b-news {
      display: block;
      margin: 50px 0;
      color: $color-main;
      @media only screen and (max-width: 600px) {
        margin: 100px 0;
      }

      .data {
        h3 {
          color: $color-third;
          padding: 0 8%;
          margin: 0 0 10px 0;
        }

        p {
          padding: 3% 8% 20px 8%;
          font-size: 1.5rem;
          line-height: 20px;
        }

        i {
          position: absolute;
          font-size: 3.0rem;
          color: $color-third;
          left: 0;
          top: 10%;
          opacity: 1;
          transition: all 0.3s ease;
        }

        .more {
          position: absolute;
          left: 8%;
          bottom: 0;
          width: 90%;
          display: block;
          text-align: right;
          font-size: 1.4rem;
          line-height: 0;
          font-family: $bold;
          padding-right: 3%;
          color: $color-main;

          &::before {
            content: '';
            position: absolute;
            width: 50%;
            left: 0;
            bottom: 0;
            height: 1px;
            background-color: $color-main;
          }
        }
      }

      &:nth-of-type(odd) {
        .data {
          i {
            right: 0;
            left: inherit;
            transform: rotate(180deg);
          }
        }

      }

    }

    .b-picto {
      @extend .b-ligne;
    }

    &:nth-of-type(1) {
      width: 41.40%;
      margin-right: 8.6%;
      background-color: $grey;
      position: relative;
      // padding-bottom: 80px;
      //&::before, &::after {
      //  content: '';
      //  position: absolute;
      //  width: 51%;
      //  height: 100%;
      //  top: 0;
      //  left: -50.5%;
      //  background-color: $grey;
      //}
      //&::after {
      //  left: inherit;
      //  right: -25%;
      //}
      h2 {
        margin-left: 25%;
        @media only screen and (max-width: 1023px) {
          margin-left: 75px;
        }

        span {
          color: $color-third;
        }

        &::before {
          background-color: $color-third;
        }
      }

      .b-newsletter {
        form p:nth-of-type(1) input {
          width: 70%;
        }
      }
    }

    .b-contenu {
      padding: 0 0 40px 0;

      h2 {
        margin-top: 20px;
      }
    }

    .b-newsletter {
      bottom: -20px;
    }

    @media only screen and (max-width: 1023px) {
      width: 100%;
      &:nth-of-type(1) {
        width: 100%;
        margin-right: 0;

        &::after, &::before {
          display: none;
        }
      }
      .b-contenu {
        padding: 0 0 40px 0;
      }
    }

  }

  .b-contenu {
    position: relative;
    z-index: 10;
    padding: 40px 16.667%;

    @media only screen and (max-width: 1023px) {
      padding: 40px 0;
    }

    &.marge-top {
      padding-top: 0;
    }

    h2, h3 {
      margin: 50px 0 50px -5px;
      position: relative;
      width: fit-content;
      @media only screen and (max-width: 1023px) {
        margin: 50px 0 50px 75px;
      }
    }

    h3 {
      margin: 30px 0 10px -5px;
      @media only screen and (max-width: 1023px) {
        margin: 30px 0 10px 75px;
      }

    }

    ul {
      margin: 10px 0;
      font-family: $bold;
      font-size: 1.4rem;

      li {
        list-style-position: inside;
        padding: 5px 0;

        ul {
          margin-left: 20px;
        }
      }
    }

    figure {
      img {
        display: block;
        width: 100%;
        height: auto;
        position: relative;
        z-index: 1;
      }

      figcaption {
        background-color: $grey;
        width: 100%;
        font-family: $bold;
        padding: 1% 3%;
        font-size: 1.3rem;
      }
    }

    .picto-brochure {
      font-family: $regular;
      font-size: 1.8rem;
      letter-spacing: .4px;
      text-transform: none;
      line-height: 19px;
      img{
        margin-right: 10px;
      }
    }

    &.img-full {
      figure {
        figcaption {
          width: 50%;
        }
      }
    }

    &.col-two {
      font-size: 0;

      div {
        font-size: 1.6rem;
        display: inline-block;
        vertical-align: top;
        width: 48.5%;
        margin: 20px 1.5% 20px 0;

        p {
          padding-left: 3%;
          padding-right: 3%;
        }

        &:nth-of-type(even) {
          margin: 20px 0 20px 1.5%;
        }

        @media only screen and (max-width: 600px) {
          width: 100%;
          margin: 20px 0;
          &:nth-of-type(even) {
            margin: 20px 0;
          }
        }
      }
    }

    &.testimonies {
      .testimony {
        font-size: 0;
        position: relative;
        width: 80vw;
        left: 50%;
        transform: translate(-50%, 0);
        @media only screen and (max-width: 768px) {
          width: 100%;
          left: auto;
          transform: none;
        }

        figure {
          font-size: 1.6rem;
          display: inline-block;
          vertical-align: top;
          width: calc(100% / 12 * 3);
          margin: 20px 0 20px calc(100% / 12);

          &:first-child {
            margin: 20px calc(100% / 12) 20px 0;
          }

          @media only screen and (max-width: 600px) {
            width: 100%;
            margin: 20px 0;
          }
        }

        div {
          font-size: 1.6rem;
          display: inline-block;
          vertical-align: top;
          width: calc(100% / 12 * 7);
          margin: 20px 0;

          span {
            font-style: italic;
            text-decoration: underline;
            display: block;
            margin: 50px 0 10px 0;
            @media only screen and (max-width: 1024px) {
              margin: 0 0 10px 0;
            }
          }

          h4 {
            margin: 0 0 30px 0;
          }

          @media only screen and (max-width: 600px) {
            width: 100%;
            margin: 20px 0;
          }
        }
      }
    }

    &.img-quart {
      div {
        padding-left: 25%;
        @media only screen and (max-width: 600px) {
          padding-left: 0;
        }
      }

      figure {
        figcaption {
          width: 66.66%;
        }
      }

      p {
        padding-right: 33.66%;
      }
    }

    &.large {
      figure {
        overflow: visible;

        img {
          max-width: none;
          width: 80vw;
          position: relative;
          left: 50%;
          transform: translate(-50%, 0);

          @media only screen and (max-width: 768px) {
            width: 100%;
            left: auto;
            transform: none;
          }
        }
      }
    }

    &.partner {
      padding: 40px 16.667% 40px 0;

      h2 {
        margin-left: 19.5%;
      }

      div {
        background-color: $grey;
        padding: 3% 20%;
      }
    }
    &.diapo-news {
      h2{
        &::before {
          background-color: $defaultLinkColorHover;
        }
        span {
          color: $defaultLinkColorHover;
        }
      }

      .diapo-news-desktop {
        position: relative;
        max-width: 800px;
        padding: 0;
        margin: 0 auto 50px;

        .swiper-container {
          margin: 0;
          padding: 40px 80px;
          background-color: $grey;
        }

        .swiper-slide {
          figure {
            img {
              display: block;
              width: 100%;
              height: auto;
            }
          }

          opacity: 0;
          &.swiper-slide-active {
            opacity: 1;
          }
        }

        .news-columns {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: start;
          align-items: stretch;

          .b-news {
            flex: 0 1 25%;
            max-width: 25%;
            &:not(:last-child) {
              margin-right: 20px;
            }

            .data {
              display: flex;
              flex-direction: column;
              height: -webkit-fill-available;
              div {
                height: -webkit-fill-available;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: stretch;
                h3 {
                  color: $defaultLinkColorHover;
                  margin: 10px 0;
                  &::before {
                    display: none;
                  }
                }
                .more {
                  color: $blue;
                  position: relative;
                  padding-left: 110px;
                  &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 9px;
                    width: 100px;
                    height: 2px;
                    background-color: $blue;
                  }
                }
              }
            }
          }
        }

        button {
          position: absolute;
          left: 0;
          bottom: -35px;
          font-size: 2.0rem;
          color: $defaultLinkColorHover;
          cursor: pointer;
          background: none;
          border: none;
          padding: 0;

          &.page-next {
            left: inherit;
            right: 0;
          }

          @media only screen and (max-width: 600px) {
            font-size: 1.2rem;
            left: 0;
            bottom: 8px;
            &.page-prev {
              left: inherit;
              right: 0;
            }
          }
        }

        .pager {
          position: absolute;
          background-color: $grey;
          width: 120px;
          left: 100%;
          bottom: -10px;
          padding: 1.5%;
          @media only screen and (max-width: 1023px) {
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: transparent;
            i {
              display: none;
            }
          }

          i {
            position: absolute;
            left: 70%;
            top: 10%;
            color: $defaultLinkColorHover;
            font-size: 0.8rem;
            cursor: pointer;
          }

          i.icon-left {
            content: "\e90a";
            left: 15%;
            bottom: 10%;
            top: inherit;
          }

          li {
            display: inline-block;
            vertical-align: middle;
            color: $defaultLinkColorHover;
            font-size: 1.4rem;
            padding: 0 5px;
            cursor: pointer;

            &.actif {
              color: $color-main;
            }

            &:nth-of-type(2) {
              position: relative;

              &::before, &::after {
                content: '';
                position: absolute;
                left: -5px;
                top: 50%;
                width: 1px;
                height: 12px;
                background-color: $color-main;
                transform: translateY(-50%);
              }

              &::after {
                left: inherit;
                right: -5px;
              }
            }
          }
        }
      }

      .diapo-news-mobile {
        position: relative;

        .swiper-container {
          margin: 0;
          padding: 0 0 30px;
          background-color: $grey;
        }

        .swiper-slide {
          figure {
            img {
              display: block;
              width: 100%;
              height: auto;
            }
          }

          opacity: 0;
          &.swiper-slide-active {
            opacity: 1;
          }
        }

        .news-columns {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;
          align-items: stretch;

          .b-news {
            flex: 0 1 100%;
            max-width: 100%;

            .data {
              display: flex;
              flex-direction: column;
              height: -webkit-fill-available;
              div {
                height: -webkit-fill-available;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: stretch;
                h3 {
                  color : $defaultLinkColorHover;
                  margin: 15px 0 45px;
                  &::before {
                    display: none;
                  }
                }
                .more {
                  color: $blue;
                  position: relative;
                  padding-left: 110px;
                  &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 9px;
                    width: 100px;
                    height: 2px;
                    background-color: $blue;
                  }
                }
              }
            }
          }
        }

        i, button {
          position: absolute;
          left: 16.667%;
          bottom: 0;
          font-size: 2.0rem;
          color: $defaultLinkColorHover;
          cursor: pointer;
          background: none;
          border: none;
          padding: 0;

          &.icon-right, &.page-next {
            left: inherit;
            right: 16.667%;
          }

          @media only screen and (max-width: 1023px) {
            font-size: 1.2rem;
            left: 0;
            bottom:-25px;
            &.icon-right, &.page-next {
              left: inherit;
              right: 0;
            }
          }
        }
      }
    }
  }

  .b-contact {
    text-align: right;

    a {
      position: relative;
      z-index: 10;
      display: inline-block;
      text-align: center;
      width: 16.66%;
      padding: 30px 0 10px 0;
      color: $white;
      font-size: 1.4rem;
      text-transform: uppercase;
      background-color: $color-second;
      transition: all 0.3s ease;

      span {
        display: block;
        text-transform: none;
        padding: 5px 0 0 0;
      }

      svg {
        margin: 0 auto 10px;
        transition: all 0.3s ease;
      }

      @media only screen and (max-width: 1023px) {
        width: 33.32%;
      }
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }

  .b-rapport {
    display: block;
    width: 100%;
    @media only screen and (max-width: 1023px) {
      margin-bottom: 20px;
    }

    i, h3 {
      vertical-align: middle;
      display: inline-block;
      width: 74%;
      text-align: center;
    }

    .pdf {
      display: inline-flex;
      width: 25%;
      background-color: $grey;
      color: $color-second;
      padding: 5% 0;
      font-size: 5.0rem;
      transition: all 0.3s ease;
      justify-content: center;
      @media only screen and (max-width: 1023px) {
        width: 100%;
      }
    }

    h3 {
      padding: 0 2% 0 40%;

      &::before {
        width: 50%;
        left: -3%;
      }

      @media only screen and (max-width: 1023px) {
        width: 100%;
        padding: 2%;
        margin: 0;
        &::before {
          display: none;
        }
      }
    }

    &:nth-of-type(even) {
      i {
        margin-left: 25%;
        @media only screen and (max-width: 1023px) {
          margin-left: 0;
        }
      }

      h3 {
        width: 49%;
        padding-left: 16%;

        &::before {
          width: 23%;
        }

        @media only screen and (max-width: 1023px) {
          width: 100%;
          padding: 2%;
        }
      }
    }
  }

  .b-newsletter {
    position: absolute;
    z-index: 30;
    bottom: 0;
    left: 5%;
    width: 90%;
    font-size: 1.4rem;
    display: none;

    h4 {
      padding: 10px 0;
      font-family: $regular;
    }

    form {
      font-size: 0;

      & > div > * {
        font-size: 1.4rem;
      }

      p {
        background-color: $color-main;
        width: 75%;
        display: inline-block;
        vertical-align: top;
        padding: 10px 0;
        box-shadow: 0 8px 20px 0 rgba($black, 0.05);
        @media only screen and (max-width: 600px) {
          width: 100%;
        }

        input {
          color: $white;
          background: transparent;
          border: none;
          border-bottom: 1px solid $white;
          width: 40%;
          margin-left: 20%;
          padding: 5px 0;
          @media only screen and (max-width: 600px) {
            width: 90%;
            margin: 0 auto;
            display: block;
          }

          &::-webkit-input-placeholder {
            color: $white;
          }

          &:-moz-placeholder { /* Firefox 18- */
            color: $white;
          }

          &::-moz-placeholder { /* Firefox 19+ */
            color: $white;
          }

          &:-ms-input-placeholder {
            color: $white;
          }

        }

        &:nth-of-type(2) {
          background-color: $white;
          width: 25%;
          padding: 0;
          @media only screen and (max-width: 600px) {
            width: 100%;
          }

          button {
            color: $color-main;
            font-family: $bold;
            text-align: center;
            width: 100%;
            margin: 0;
            padding: 17px 0;
            background-color: $white;
            border: 0;
            outline: 0;
            cursor: pointer;
          }
        }
      }

      & > p {
        display: none;
      }
    }

    .mc4wp-alert {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      max-width: 80%;
      padding: 20px;
      background-color: rgba($color-third, 0.85);
      box-shadow: 0 4px 12px 0 rgba($color-main, 0.41);

      p {
        width: 100%;
        color: $white;
        text-align: center;
        font-family: $bold;
        background-color: transparent;
        text-transform: uppercase;
        cursor: pointer;

        &::after {
          position: absolute;
          top: 5px;
          right: 10px;
          content: 'x';
          color: $white;
          font-size: 1.6rem;
          font-family: $regular;
        }
      }
    }
  }

  .b-video {
    padding: 40px 0;
  }

  .b-logo {
    font-size: 0;
    position: relative;
    z-index: 10;
    @media only screen and (max-width: 600px) {
      margin-bottom: 40px;
    }

    figure, p {
      display: inline-block;
      vertical-align: middle;
      width: 50%;
      font-size: 1.6rem;
      font-family: $bold;
      position: relative;
      text-align: center;
      @media only screen and (max-width: 600px) {
        display: block;
        width: 100%;
      }
    }

    figure {
      background-color: $grey;
      padding: 5% 10%;
    }

    p {
      padding-left: 20%;

      &::before {
        content: '';
        position: absolute;
        height: 1px;
        background-color: $color-main;
        width: 40%;
        left: -5%;
        top: 50%;
        transform: translateY(-50%);
      }

      a {
        color: $color-main;
      }

      @media only screen and (max-width: 600px) {
        padding: 2% 0;
        &::before {
          display: none;
        }
      }
    }

  }

  .b-photos {
    font-size: 0;
    position: relative;
    z-index: 10;

    @media only screen and (max-width: 600px) {
      margin-bottom: 40px;
    }

    figure, p {
      display: inline-block;
      vertical-align: middle;
      width: 50%;
      font-size: 1.6rem;
      font-family: $bold;
      position: relative;
      text-align: center;

      @media only screen and (max-width: 600px) {
        display: block;
        width: 100%;
      }
    }

    figure {
      background-color: $grey;
      padding: 5% 10%;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: url('../../images/png.png') center center no-repeat;
      }
    }

    p {
      padding-right: 20%;

      &::after {
        content: '';
        position: absolute;
        height: 1px;
        background-color: $color-main;
        width: 40%;
        right: -5%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
      }

      a {
        color: $color-main;
      }

      @media only screen and (max-width: 600px) {
        padding: 2% 0;

        &::after {
          display: none;
        }
      }
    }
  }

  .diapo-tweet {
    margin-left: 50%;
    background-color: $color-main;
    color: $white;
    position: relative;
    @media only screen and (max-width: 600px) {
      margin: 0 0 40px 0;
    }

    .swiper-slide {
      p {
        padding: 50px 20px;
      }

    }

    button {
      position: absolute;
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
      color: $white;

      &.page-prev {
        left: 5px;
        bottom: 5px;
      }

      &.page-next {
        right: 5px;
        bottom: 5px;
      }
    }

    .title {
      position: absolute;
      top: 10px;
      left: 20px;
      padding-left: 40px;
      font-family: $bold;

      svg {
        color: $white;
        position: absolute;
        left: 0;
      }
    }

  }

  .b-actu {
    background-color: $color-second;
    width: 50%;
    @media only screen and (max-width: 600px) {
      width: 100%;
    }

    p {
      padding: 5px 15px;
      color: $white;
      font-size: 1.4rem;
      line-height: normal;

      &.title {
        padding: 20px 15px 0 15px;
        font-family: $bold;
        font-size: 1.6rem;
      }

      &.date {
        font-family: $italic;
        text-decoration: underline;
        padding-bottom: 15px;
      }
    }

    a.more {
      @extend .more-filaire;
    }
  }

  .b-list {
    display: block;
    width: 100%;
    font-size: 0;
    position: relative;
    font-family: $bold;

    div, h3 {
      vertical-align: middle;
      display: inline-block;
      width: 50%;
      font-size: 1.6rem;
      color: $color-main;
      transition: all 0.3s ease;
    }

    div {
      padding: 5% 3%;
      background-color: $grey;

      p {
        &.date {
          font-family: $italic;
          text-decoration: underline;
          font-size: 1.4rem;
        }
      }
    }

    h3 {
      text-align: center;
      padding: 0 0 0 11%;
      margin: 0;

      &::before {
        background-color: $color-main;
        left: -3%;
        width: 20%;
      }
    }

    .cat {
      position: absolute;
      top: -32px;
      text-transform: uppercase;
      color: $grey;
      left: 2%;
      font-size: 4.0rem;
      transition: all 0.3s ease;
    }

    &:nth-of-type(even) {
      h3 {
        padding: 0 11% 0 0;

        &::before {
          left: inherit;
          right: -3%;
        }
      }

      .cat {
        left: 52%;

      }
    }
  }

  .bt-actu {
    @extend .bt-more;
    width: 25%;
    border: none;
    margin: 40px 0 40px 25%;
  }

  .bt-recru {
    @extend .bt-more;
    width: 25%;
    border: none;
    margin: 40px 0 40px 25%;
  }

  .b-agenda {
    display: table;
    width: 100%;
    position: relative;
    z-index: 10;

    &:nth-of-type(1) {
      padding-top: 60px;
    }

    & > div {
      display: table-cell;
      width: 25%;
      text-align: center;
      @media only screen and (max-width: 600px) {
        width: 100%;
        display: block;
        margin-bottom: 20px;
        &.empty {
          display: none;
        }
      }
    }

    .b-date {
      background-color: $grey;
      color: $color-main;

      div {
        padding: 20% 2%;
        position: relative;

        &:nth-of-type(1) {
          background-color: $color-main;
          color: rgba($white, 0.5);
          font-size: 70px;
          font-family: $bold;
          padding: 8% 2% 20% 2%;

          p {
            position: absolute;
            width: 100%;
            left: 0;
            top: 70%;
            transform: translateY(-50%);
            color: $white;
            text-transform: uppercase;
            font-size: 2.0rem;
            font-family: $regular;

            span {
              display: block;
              font-family: $bold;
            }
          }
        }
      }

    }
  }

  .b-annonces {
    padding: 20px 0;

    .b-annonce {
      display: table;

      figure, h3 {
        display: table-cell;
        width: 50%;
        vertical-align: middle;
        color: $color-main;
        font-size: 1.6rem;

      }

      figure {
        position: relative;

        figcaption {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          opacity: 0;
          transition: all 0.3s ease;
          background-color: $color-second;
        }
      }

      h3 {
        position: relative;
        padding: 0 1% 0 15%;
        margin: 0;

        &::before {
          width: 25%;
          left: -3%;
          background-color: $color-main;
        }

        span {
          font-family: $italic;
          text-decoration: underline;
          display: block;
          font-size: 1.4rem;
          padding-bottom: 10px;
        }
      }

      &:nth-of-type(even) {
        h3 {
          text-align: right;
          padding: 0 15% 0 1%;

          &::before {
            left: inherit;
            right: -3%;
            z-index: 20;
          }
        }
      }

      @media only screen and (max-width: 600px) {
        display: block;
        width: 100%;
        margin-bottom: 40px;
        figure, h3 {
          display: block;
          width: 100%;
        }
        h3, &:nth-of-type(even) h3 {
          padding: 2% 0;
          text-align: center;

          &::before {
            display: none;
          }
        }

      }
    }

    .annonces-more {
      display: none;
    }

    .more {
      @extend .more-filaire;
      width: 50%;

      &::before {
        width: 30%;
      }

      @media only screen and (max-width: 600px) {
        width: 100%;
        text-align: center;

        &::before {
          display: none;
        }
      }
    }
  }

}

.presse {
  section {
    .b-contact {
      margin-right: 16.667%;

      a {
        width: 40%;
      }

      @media only screen and (max-width: 600px) {
        margin-right: 0;
        a {
          width: 100%;
        }
      }
    }
  }
}

.page-template-gab-czech {
  font-family: "Avenir Next W05 Regular";

  h1, h2, h3, h4, h5, h6, strong, b {
    font-family: "Avenir Next W05 Bold";
  }

  section {
    padding: 108px 30px 0 30px;

    @media only screen and (max-width: 767px) {
      padding: 0 15px;
    }
  }

  aside {
    &.popin-container {
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 10;
      display: none;

      .overlay {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background: $black;
        opacity: .9;
      }

      .popin {
        position: relative;
        left: 50%;
        top: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        background: $white;
        width: 80%;
        max-width: 800px;
        max-height: 800px;

        iframe {
          width: 100%;
        }

        .close {
          position: absolute;
          right: 0;
          top: -30px;
          height: 24px;
          cursor: pointer;

          span {
            display: block;
            height: 1px;
            width: 26px;
            background: $grey;
            transform: translate(0, 11px) rotate(-45deg);

            &:first-child {
              transform: translate(0, 12px) rotate(45deg);
            }
          }
        }
      }
    }
  }

  .b-onglets {
    position: fixed;
    left: 0;
    top: 57px;
    z-index: 11;
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  .b-map {
    padding: 40px 0 0 0;
    margin: 0 -30px;

    @media only screen and (max-width: 767px) {
      margin: 0;
    }
  }

  &.logged-in.admin-bar {
    .b-onglets {
      top: 89px;
    }
  }
}

/* FOOTER */
footer {
  padding: 0 30px;
  //box-shadow: 0 2px 12px 0 rgba(178, 178, 178, 0.3);
  box-shadow: 12px 0 12px 0 rgba(178, 178, 178, 0.3);

  .top {
    position: relative;

    ul {
      width: 100%;
      background-color: $grey;

      & > li {
        width: 16.66667%;
        font-size: 1.2rem;
        display: inline-block;
        text-align: center;
        padding: 5px 0;
        font-family: $bold;

        a {
          color: $color-second;
          display: block;
        }

        &:nth-of-type(1) {
          display: inline-flex;
          justify-content: center;
          gap: 16px;
          border-right: 1px solid $color-second;

          a {
            color: $color-main;
          }
        }

        &:nth-of-type(2) {
          background-color: $color-second;

          a {
            color: $white;
          }
        }

        &:nth-of-type(4) {
          width: 50%;
          background-color: $color-main;
          color: $white;

          span {
            color: $color-second;
            padding-left: 8%;
          }
        }

        @media only screen and (max-width: 1023px) {
          width: 33.32%;
          &:nth-of-type(4) {
            width: 100%;
          }
        }
        @media only screen and (max-width: 600px) {
          width: 100%;
          padding: 15px 0;
          &:nth-of-type(1) {
            border: none;
          }
          &:nth-of-type(4) {
            span {
              display: block;
              padding: 0;
            }
          }
        }
      }
    }
  }

  .bottom {
    background-color: $white;
    box-shadow: 2px 0 10px 0 rgba(171, 171, 171, 0.13);

    a {
      color: $color-main;
    }

    .center-wrap {
      position: relative;

      ul {
        font-size: 1.2rem;
        text-align: center;
        margin-right: 8%;

        li {
          display: inline-block;
          padding: 20px 2.5%;
        }
      }

      .scroll-top {
        position: absolute;
        right: 0;
        top: 0;
        background-color: $grey;
        width: 8%;
        height: 100%;
        cursor: pointer;

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $color-second;
        }
      }
    }
  }
}

/* Cas particuliers */
.recrutement, .jobs {
  section {
    .b-contact {
      display: none;
    }
  }
}

#popin-bourget {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  display: none;

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(11, 38, 89, .9);
    z-index: 1;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 70%;
      height: 50%;
      background: url('../../images/clouds.png') left bottom no-repeat;
      background-size: contain;
    }
  }

  .popin-container {
    background: $white;
    height: 500px;
    width: 900px;
    max-height: 80%;
    max-width: 80%;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 10px;
    box-shadow: 0 22px 134px 0 rgba(19, 42, 87, 0.3);
    padding: 25px 50px;

    @media only screen and (max-width: 767px) {
      padding: 20px 20px 0 20px;
      max-width: 90%;
      overflow-y: auto;
    }

    .close {
      position: absolute;
      top: 25px;
      right: 25px;
      border: 1px solid #012ea7;
      border-radius: 50%;
      height: 42px;
      width: 42px;
      cursor: pointer;

      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        height: 1px;
        width: 18px;
        background: #012ea7;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    .text-container {
      margin: 110px 0 150px;

      @media only screen and (max-width: 767px) {
        margin: 70px 0;
      }

      p {
        font-size: 35px;
        color: #012ea7;
        font-weight: 300;
        text-align: center;

        @media only screen and (max-width: 767px) {
          font-size: 23px;
        }

        span {
          display: block;
          margin: 0 0 30px 0;
          color: #6994e6;
          font-size: 33px;
          letter-spacing: .7px;
        }
      }

      .compteur {
        position: relative;
        margin: 50px auto 0;
        display: block;
        padding: 0 50px;
        width: 410px;
        text-align: center;

        @media only screen and (max-width: 767px) {
          padding: 0 10px;
          width: auto;
          margin: 50px -10px;
          text-align: center;
        }

        &::before, &::after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          height: 1px;
          width: 100%;
          background-image: linear-gradient(to right, rgba(151, 169, 217, 0), rgba(151, 169, 217, 0.82) 50%, rgba(151, 169, 217, 0));
        }

        &::after {
          top: auto;
          bottom: 0;
        }

        > div {
          font-size: 26px;
          color: #0b2659;
          letter-spacing: 1.9px;
          display: inline-block;
          vertical-align: top;
          line-height: 1em;
          padding: 20px 20px 15px;
          letter-spacing: .6px;

          @media only screen and (max-width: 767px) {
            padding: 20px 10px 15px;
          }

          span {
            text-shadow: 0 2px 4px rgba(2, 88, 254, .25);
            font-weight: 700;
          }

          &#heures {
            border-left: 1px solid #dae0f2;
            border-right: 1px solid #dae0f2;
          }
        }
      }
    }

    & > a {
      position: absolute;
      right: 0;
      bottom: 0;
      background: #0b2659;
      padding: 25px 115px 25px 30px;
      color: $white;
      font-weight: 700;
      font-size: 19px;
      cursor: pointer;
      box-shadow: 0 10px 44px 0 rgba(26, 41, 70, 0.33);
      outline: none;
      border-radius: 0 0 10px 0;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 55px;
        height: 9px;
        width: 9px;
        background: $white;
        opacity: .8;
        border-radius: 50%;
        transform: translate(0, -50%);
        animation: dot 2s ease-out infinite;
      }

      @keyframes dot {
        0% {
          opacity: 1;
        }
        40% {
          opacity: .5;
        }
        70% {
          opacity: .5;
        }
        100% {
          opacity: 1;
        }
      }

      &::after {
        content: '';
        position: absolute;
        right: 5px;
        top: 50%;
        height: 106px;
        width: 106px;
        border: 1px solid #0b2659;
        border-radius: 50%;
        transform: translate(0, -50%);
        animation: pulse 2s ease-out infinite;
      }

      @keyframes pulse {
        0% {
          transform: translate(0, -50%) scale(.5);
          opacity: 0;
        }
        30% {
          transform: translate(0, -50%) scale(.5);
          opacity: 0;
        }
        60% {
          opacity: 1;
        }
        100% {
          transform: translate(0, -50%) scale(1);
          opacity: 0;
        }
      }

      @media only screen and (max-width: 767px) {
        float: right;
        margin: 0 -20px 0 0;
        position: relative;
      }
    }
  }
}

.page-child {

  section {

    .b-contenu {

      a {

        &.more {
          position: relative;

          &::before {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: 50%;
            height: 1px;
            width: 25vw;
            background: #f19668;
            z-index: -1;
          }
        }
      }
    }

    .b-contact {
      padding: 40px 16.667%;
      p {
        width: 50%;
        text-align: left;
        background-color: $grey;
        padding: 4% 6%;
        font-size: 1.5rem;
        margin: 0 0 0 50%;

        &:first-letter {
          font-size: 2.8rem;
          font-family: $bold;
          text-transform: uppercase;
        }
      }

      a {
        width: 50%;
      }
    }
  }
}

.page-template-gab-service-clients-activites {

  section {

    .b-contenu {

      ul {
        font-family: $regular;
      }

      &.col-two {

        div {
          width: 50%;
          margin: 20px 0;

          &:nth-of-type(2n) {
            background-color: #f3f3f8;
            padding: 0 40px 20px 40px;

            h3 {

              &::before {
                content: none;
              }

              ul {
                font-family: $regular;
              }
            }
          }
        }
      }
    }
  }
}

#tarteaucitronRoot{
  #tarteaucitronAlertBig{
    background: #000000 !important;
    br{
      display: none;
    }
  }
}
