@font-face {
    font-family:"Avenir Next W05 Bold";
    src: url("../../fonts/avenirnext-bold-cz.woff2") format("woff2"),
         url("../../fonts/avenirnext-bold-cz.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family:"Avenir Next W05 Italic";
    src: url("../../fonts/avenirnext-italic-cz.woff2") format("woff2"),
         url("../../fonts/avenirnext-italic-cz.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family:"Avenir Next W05 Regular";
    src: url("../../fonts/avenirnext-regular-cz.woff2") format("woff2"),
         url("../../fonts/avenirnext-regular-cz.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: 'avenir_nextbold';
    src: url('../../fonts/avenirnext-bold-webfont.woff2') format('woff2'),
    url('../../fonts/avenirnext-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'avenir_nextitalic';
    src: url('../../fonts/avenirnext-italic-webfont.woff2') format('woff2'),
    url('../../fonts/avenirnext-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'avenir_nextregular';
    src: url('../../fonts/avenirnext-regular-webfont.woff2') format('woff2'),
    url('../../fonts/avenirnext-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
