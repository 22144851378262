.b-onglets {
    width: 100%;
    background-color: $color-main;
    font-size: 1.4rem;
    letter-spacing: 1px;
    font-family: $regular;

    figure {
        width: 100%;

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    ul {
        width: 100%;
        max-width: $wrap;
        margin: 0 auto;
        display: table;
        table-layout: fixed;

        li {
            display: table-cell;

            a {
                display: inline-block;
                padding: 15px 10px;
                color: $white;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 4px;
                    background-color: $white;
                    width: 0;
                    transition: all 0.3s ease;
                }

                &.actif {
                    font-family: $bold;

                    &::after {
                        width: 100%;
                    }
                }
            }
        }

        @media only screen and (max-width: 600px) {
            display: block;
            li {
                display: inline-block;
                width: 49%;
                font-size: 1.4rem;

                a {
                    padding: 20% 10%;
                    text-align: center;
                    display: block;

                    &::after {
                        display: none;
                    }

                    &.actif {
                        background-color: $color-second;
                    }
                }
            }
        }
    }
}

.diapo-presentation {
    font-size: 0;
    position: relative;
    @media only screen and (max-width: 600px) {
        margin-bottom: 30px;
    }

    figure, figcaption {
        display: inline-block;
        vertical-align: middle;
        width: 50%;
        @media only screen and (max-width: 600px) {
            display: block;
            width: 100%;
        }
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    figcaption {
        font-size: 1.8rem;
        color: $color-second;
        text-transform: uppercase;
        padding-left: 5%;
        @media only screen and (max-width: 600px) {
            padding: 10px;
            text-align: center;
        }
    }

    .page-prev, .page-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 20;
        color: $color-second;
        cursor: pointer;
        background: none;
        border: none;
        padding: 0;
    }

    .page-prev {
        left: -2%;
    }

    .page-next {
        left: 46.5%;
    }
}

.b-number {
    margin-left: 50%;
    @media only screen and (max-width: 600px) {
        margin: 0 0 40px 0;
    }

    div {
        background-color: $grey;
        position: relative;
        text-align: center;
        padding: 10% 0;
        font-size: 7.0rem;
        letter-spacing: 3.9px;
        font-family: $bold;
        color: rgba($defaultTexteColor, 0.2);

        span {
            position: absolute;
            left: 50%;
            top: 80%;
            transform: translate(-50%, -80%);
            font-size: 2.0rem;
            letter-spacing: 1.1px;
            font-family: $regular;
            text-transform: uppercase;
            color: $defaultTexteColor;
            width: 100%;
        }
    }

    .more {
        @extend .more-filaire;
    }
}

.b-infos {
    margin-right: 50%;
    @media only screen and (max-width: 600px) {
        margin: 0 0 40px 0;
    }

    p {
        background-color: $color-second;
        color: $white;
        padding: 4% 6%;
        font-size: 1.4rem;
        line-height: 1.36;
        letter-spacing: 0.5px;
    }

    .more {
        @extend .more-filaire;
    }

}

.gallery {
    position: relative;
    z-index: 10;
    padding: 40px 16.667%;
    @media only screen and (max-width: 1023px) {
        padding: 40px 0;
    }

    &.upload {
        padding-top: 0;
        padding-bottom: 200px;
        @media only screen and (max-width: 600px) {
            padding-bottom: 40px;
        }
    }

    .filtres {
        text-align: right;
        position: relative;

        p {
            display: inline-block;
            width: 25%;
            background-color: $grey;
            text-align: left;
            position: relative;
            font-family: $bold;
            padding-left: 10px;
            cursor: pointer;

            svg {
                position: absolute;
                right: 10px;
                top: 50%;
                transition: all 0.3s ease;
                transform: translateY(-50%) rotate(180deg);
            }

            &.actif {
                svg {
                    transform: translateY(-50%) rotate(0deg);
                }
            }
        }

        ul {
            display: none;
            position: absolute;
            z-index: 20;
            font-size: 1.4rem;
            right: 0;
            top: 36px;
            width: 25%;
            text-align: left;
            background-color: $white;
            padding: 5px 0;
            box-shadow: 0 2px 12px 0 rgba(#000, 0.52);

            li {
                list-style: none;
                padding: 5px 10px;
                display: block;
                text-align: left;
                width: 100%;
            }
        }

        @media only screen and (max-width: 600px) {
            p, ul {
                width: 100%;
            }

        }
    }

    ul {
        font-size: 0;
        background-color: $color-second;
        position: relative;
    }

    li {
        display: inline-block;
        vertical-align: middle;
        width: 25%;
        font-size: 1.4rem;
        color: $white;
        font-family: $bold;
        text-align: center;

        figure {
            position: relative;

            figcaption {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba($color-second, 0.7);
                text-align: center;
                color: $white;
                opacity: 0;
                transition: all 0.3s ease;

                span {
                    position: absolute;
                    top: 70%;
                    left: 50%;
                    transform: translate(-50%, -70%);
                    text-transform: uppercase;
                    text-decoration: underline;
                    font-size: 2.0rem;
                    color: $white;

                    &:first-child {
                        font-size: 7.0rem;
                        font-family: $bold;
                        color: rgba($white, 0.6);
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-decoration: none;
                        text-transform: none;
                    }
                }
            }
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }

        .infos {
            position: absolute;
            z-index: 30;
            padding: 3% 5%;
            background-color: $color-second;
            color: $white;
            width: 50%;
            text-align: left;
            display: none;

            p {
                font-size: 1.6rem;
                padding: 3px;

                span {
                    font-family: $bold;
                    display: block;
                }

                &.date {
                    font-family: $italic;
                    text-decoration: underline;
                    font-size: 1.4rem;
                }
            }
        }

        &:nth-of-type(n) {
            .infos {
                left: 25%;
            }
        }

        &:nth-of-type(2n) {
            .infos {
                left: 50%;
            }
        }

        &.citation {
            @extend .temoignages;
            width: 50%;
            border: none;
        }

        @media only screen and (max-width: 600px) {
            width: 100%;
            &.citation {
                width: 100%;
            }
        }
    }
}

.diapo-product {
    padding: 40px 0 40px 16.667%;
    position: relative;
    z-index: 10;
    @media only screen and (max-width: 1023px) {
        padding: 40px 0;
    }

    .swiper-container {
        margin: 0 20% 0 0;
        @media only screen and (max-width: 1023px) {
            margin: 0;
        }
    }

    .swiper-slide {
        position: relative;

        figure {
            width: 50%;
            position: relative;
            @media only screen and (max-width: 600px) {
                width: 100%;
            }

            img {
                display: block;
                width: 100%;
                height: auto;
            }

            figcaption {
                position: absolute;
                width: 100%;
                background-color: rgba($white, 0.85);
                bottom: 0;
                left: 0;
                padding: 2%;
                text-transform: uppercase;
                color: $color-second;
                font-size: 1.8rem;
                letter-spacing: 1px;
                padding-left: 25%;

                &::after {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: 20%;
                    left: 0;
                    top: 50%;
                    background-color: $color-second;
                }

            }
        }

        div {
            position: absolute;
            right: 0;
            top: 0;
            width: 50%;
            height: 50%;
            background-color: $grey;

            ul {
                padding: 2%;

                li {
                    display: inline-block;
                    width: 49%;
                    vertical-align: middle;
                    padding: 5px 5px 5px 15px;
                    position: relative;

                    &::before {
                        content: '';
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        background-color: $color-main;
                    }
                }
            }

            &.citation {
                @extend .temoignages;
                position: absolute;
                bottom: 0;
                top: inherit;
                background-color: $color-second;
                color: $white;
                text-align: center;
            }

            @media only screen and (max-width: 600px) {
                position: relative;
                top: inherit;
                right: inherit;
                width: 100%;
                height: auto;
                ul {
                    li {
                        display: block;
                        width: 100%;
                    }
                }
                &.citation {
                    position: relative;
                    bottom: inherit;
                }
            }
        }
    }

    .page-prev, .page-next {
        position: absolute;
        bottom: 0;
        color: $color-second;
        cursor: pointer;
        background: none;
        border: none;
        padding: 0;
    }

    .page-prev {
        left: 16.667%;
    }

    .page-next {
        right: 16.667%;
    }

    .pager {
        position: absolute;
        background-color: $grey;
        left: 83.335%;
        bottom: 40px;
        padding: 1.5%;
        @media only screen and (max-width: 1023px) {
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: transparent;
            i {
                display: none;
            }
        }

        .page-prev, .page-next {
            position: absolute;
            bottom: initial;
            right: initial;
            color: $color-second;
        }

        .page-prev {
            left: 15%;
            bottom: 10%;
        }

        .page-next {
            left: 70%;
            top: 10%;
        }

        li {
            display: inline-block;
            vertical-align: middle;
            color: $color-second;
            font-size: 1.4rem;
            padding: 0 5px;
            cursor: pointer;

            &.actif {
                color: $color-main;
            }

            &:nth-of-type(2) {
                position: relative;

                &::before, &::after {
                    content: '';
                    position: absolute;
                    left: -5px;
                    top: 50%;
                    width: 1px;
                    height: 12px;
                    background-color: $color-main;
                    transform: translateY(-50%);
                }

                &::after {
                    left: inherit;
                    right: -5px;
                }
            }
        }
    }
}

.b-product {
    padding: 40px 16.667%;
    position: relative;
    z-index: 20;
    @media only screen and (max-width: 1023px) {
        padding: 40px 0;
    }

    h3 {
        margin-left: -5px;
        @media only screen and (max-width: 1023px) {
            margin-left: 75px;
        }
    }

    .list {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        @media only screen and (max-width: 600px) {
            padding-top: 0;
        }

        & > ul {
            background-color: $grey;
            padding: 3%;
            font-size: 1.4rem;
            width: 50%;
            @media only screen and (max-width: 600px) {
                width: 100%;
            }
        }

        li {
            list-style-type: none;
            padding: 10px 0;
            cursor: pointer;

            &.actif {
                text-decoration: underline;
                font-family: $bold;
            }
        }

        .products-row {
            width: 50%;
            div {
                width: 100%;
                background-color: $color-main;
                color: $white;
                @media only screen and (max-width: 600px) {
                    width: 100%;
                    margin: 20px 0 0 0;
                }

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }

                ul {
                    padding: 3% 0;
                }

                li {
                    cursor: inherit;
                    list-style-type: disc;
                    list-style-position: inside;
                    color: $white;
                    margin-left: 36%;
                    font-size: 1.2rem;
                    @media only screen and (max-width: 600px) {
                        margin-left: 10px;
                    }
                }
            }
        }

        .title {
            position: absolute;
            top: 10%;
            left: 0;
            width: 50%;
            color: $color-second;
            text-transform: uppercase;
            text-align: center;
            font-size: 1.8rem;
            padding: 0 5%;
            @media only screen and (max-width: 600px) {
                display: none;
            }

            &::after {
                content: '';
                position: absolute;
                z-index: 20;
                height: 1px;
                width: 14%;
                right: -4%;
                top: 15px;
                background-color: $color-second;
            }
        }
    }
}

.b-production {
    padding: 40px 16.667% 80px 16.667%;
    position: relative;
    z-index: 20;
    @media only screen and (max-width: 1023px) {
        padding: 40px 0 80px 0;
        h2 {
            margin-left: 75px;
        }
    }

    .list {
        font-size: 0;

        & > * {
            font-size: 1.4rem;
        }

        figure, a.more, ul, .b-contact {
            display: inline-block;
            vertical-align: middle;
            width: 50%;
            @media only screen and (max-width: 600px) {
                width: 100%;
            }
        }

        figure {
            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }

        ul {
            padding: 0 4%;

            li {
                list-style-position: inside;
                padding: 3% 0;
            }
        }

        a.more {
            @extend .more-filaire;
            vertical-align: top;

            span {
                padding-right: 10px;
            }

        }

        .b-contact {
            padding: 0;
            p {
                text-align: left;
                background-color: $grey;
                padding: 4% 6%;
                font-size: 1.5rem;
                margin: 0;
                width: 100%;

                &:first-letter {
                    font-size: 2.8rem;
                    font-family: $bold;
                    text-transform: uppercase;
                }
            }

            a {
                width: 100%;
            }
        }
    }
}

.b-services {
    padding: 40px 16.667%;
    position: relative;
    z-index: 10;
    @media only screen and (max-width: 1023px) {
        padding: 40px 0;
        h3 {
            margin-left: 75px;
        }
    }

    .b-service {
        @extend .b-ligne;
        font-size: 0;
        cursor: pointer;

        .data {
            figure, div {
                display: inline-block;
                width: 50%;
                vertical-align: middle;
                position: relative;
                @media only screen and (max-width: 600px) {
                    width: 100%;
                }
            }

            figure {
                box-shadow: 0px 2px 10px 0px rgba(#ababab, 0.3);

                figcaption {
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    opacity: 0;
                    transition: all 0.3s ease;
                }
            }

            div {
                h3 {
                    border: none;
                    width: 100%;
                    padding-left: 30%;
                    margin-left: 0;

                    &::before {
                        width: 30%;
                        left: -5%;
                    }

                    @media only screen and (max-width: 600px) {
                        padding-left: 0;
                        margin: 30px 0;
                        text-align: center;
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        .infos {
            display: none;
            background-color: $grey;
            width: 50%;
            padding: 3%;
            position: relative;
            @media only screen and (max-width: 600px) {
                width: 100%;
            }

            .top {
                position: absolute;
                right: 0;
                bottom: 0;
                color: $white;
                background-color: $color-second;
                padding: 8px 15px;
            }
        }

        &:nth-of-type(even) {
            .data {
                h3 {
                    &::before {
                        left: inherit;
                        right: -5%;
                        z-index: 10;
                    }
                }
            }

            .infos {
                margin-left: 50%;
                @media only screen and (max-width: 600px) {
                    margin-left: 0;
                }
            }
        }

        &.actif {
            .data {
                figure {
                    figcaption {
                        opacity: 0.7;
                    }
                }
            }
        }

    }
}

.b-chart {
    padding: 40px 0 40px 6%;
    position: relative;
    z-index: 20;
    display: inline-block;
    vertical-align: top;
    width: 49.5%;

    p.title {
        font-family: $bold;
        text-transform: uppercase;
        padding-bottom: 30px;
    }

    .bars {
        width: 80%;

        .legendes {
            text-align: center;

            li {
                display: inline-block;
                margin: 10px;

                span {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    margin-right: 12px;
                }
            }
        }
    }

    canvas {
        width: 100% !important;
        height: auto !important;
        background-color: $white;
    }

    .doughnut {
        width: 80%;

        canvas, .legendes {
            display: inline-block;
            vertical-align: top;
        }

        canvas {
            width: 70% !important;
        }

        .legendes {
            width: 28%;

            li {
                list-style-type: none;
                padding: 15px 0;
                border-bottom: 1px solid $color-main;
                padding-left: 50px;
                position: relative;

                &:first-child {
                    border-top: 1px solid $color-main;
                }

                span {
                    width: 23px;
                    height: 23px;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    &.doughnut-legend-text {
                        width: max-content;
                        left: 30px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1023px) {
        padding: 40px 0;
        display: block;
        width: 100%;
        h3 {
            margin-left: 75px;
        }
    }
    @media only screen and (max-width: 600px) {
        .bars, .doughnut {
            width: 100%;

            canvas {
                width: 100% !important;
            }

            .legendes {
                display: block;
                width: 92%;
                margin: 30px auto;
            }
        }
    }
}

.page-template-gab-finances-statistiques {
    .b-chart {
        padding: 40px 0;

        div {
            margin: 0 8.333%;
        }

        small {
            font-size: 1.1rem;
        }
        .row-button-range{
            display: inline-block;
            margin: 10px 0 40px;
            .button-range{
                display: inline;
                background-color: #f3f3f8;
                border: solid 1px #f3f3f8;
                color: #0a2757;
                padding: 8px 20px;
                text-align: center;
                text-decoration: none;
                font-size: 13px;
                letter-spacing: 1px;
                font-weight: bold;
                margin-right: 5px;
                &.active{
                    background-color: #0a2757;
                    color: #f3f3f8;
                }
                &:last-child{
                    margin-right: 0;
                }
                &:hover{
                    background-color: #0a2757;
                    color: #f3f3f8;
                }
            }
        }
    }

    .table-responsive {
        margin-bottom: 15px;
        overflow-y: hidden;
        padding: 40px 8.333%;
        position: relative;
        z-index: 10;
    }

    .table-responsive {
        min-height: 0.01%;
        overflow-x: auto;
    }

    table.stats {
        width: 100%;
        margin: 20px 0 5px;
        border-spacing: 2px;
        border-collapse: separate;

        thead td {
            font-size: 1.6rem;
            color: #FFF;
            background: $color-main;
            padding: 6px;
        }

        tbody td {
            color: $color-main;
            font-size: 1.3rem;
            background-color: $grey;
            padding: 5px;

            span {
                font-family: $bold;

                &.moins {
                    color: #ff5858;
                }

                &.dernier {
                    font-size: 2.2rem;
                }
            }

            &.color {
                background-color: #d3d3e0;
            }
        }
    }
}

.levolution-de-laction, .evolution-of-shares {
    .b-chart {
        width: 100%;
    }
}

//.presentation {
//  .center-wrap {
//    max-width: inherit;
//  }
//}
