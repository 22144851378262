.no-touch {

  .header {
    nav {
      li:hover {
        &::after {
          width: 100%;
        }
        ul {
          li {
            a:hover {
              color: $color-second;
            }
          }
        }
        &.contact {
          a {
            &::before {
              color: $color-second;
            }
          }
        }
      }
    }
  }

  .bt-more:hover {
    background-color: $color-second;
  }
  .col {
    .b-news:hover {
      .more {
        color: $white;
      }
    }
  }
  .b-news:hover {
    .data {
      & > *, p {
        color: $color-third;
      }
      .more {
        color: $color-third;
        &::before {
          background-color: $color-third;
        }

      }
      figure {
        figcaption {
          opacity: 0.7;
        }
        i {
          opacity: 0;
        }
      }
    }
  }
  .single {
    .b-news:hover {
      .data {
        & > *, p {
          color: $white;
        }
        .more {
          color: $white;
          &::before {
            background-color: $white;
          }

        }
      }
    }
  }

  .b-picto:hover, .b-service:hover {
    .data {
      figure {
        figcaption {
          opacity: 0.7;
        }
      }
    }
  }

  .b-contact {
    a:hover {
      color: $color-main;
      i {
        color: $color-main;
      }
    }
  }

  .b-onglets {
    ul li a:hover {
      &::after {
        width: 100%;
      }
    }
  }

  .filtres {
    li:hover {
      background-color: $color-main;
      a {
        color: $white;
      }
    }
  }
  .b-logo {
    p a:hover {
      text-decoration: underline;
    }
  }

  .b-annonce:hover {
    figure {
      figcaption {
        opacity: 0.7;
      }
    }
    h3 {
      color: $color-second;
      &::before {
        background-color: $color-second;
      }
    }
  }

  .gallery {
    li:hover {
      figcaption {
        opacity: 1;
      }
    }
  }
  .b-rapport:hover {
    i {
      color: $white;
      background-color: $color-second;
      &::before {
        color: $white;
      }
    }
    h3 {
      text-decoration: underline;
    }
  }

  .b-list:hover {
    color: $white;
    div {
      background-color: $color-main;
      p {
        color: $white;
      }
    }
    h3 {
      text-decoration: underline;
    }
    .cat {
      color: $color-main;
    }
  }

  footer {
    a:hover {
      color: $color-second;
      i {
        color: $color-second;
      }
    }
    .top {
      a:hover {
        i {
          color: $color-second;
        }
      }
    }
  }

}
