.home {
    /*@media only screen and (min-width: 1024px) {
        overflow: hidden;
    }*/

    .center-wrap {
        max-width: inherit;
    }

    section {
        padding-top: 0;

        .col {
            margin-bottom: 0;
        }
    }
}

.popin-mission {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 990;
    background-color: $color-second;

    @media only screen and (max-width: 1023px) {
        right: 50%;
        transform: translateX(50%);
    }

    .popin-mission-content-cta {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: fit-content;
        color: $white;
        border: 1px solid $white;
        font-family: $bold;
        padding: 5px 30px;
        font-size: 1.7rem;
        letter-spacing: 0.2px;
        margin-bottom: 10px;
    }

    .close-mission {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        background: none;
        border: none;

        &::after {
            content: 'X';
            display: block;
            width: 15px;
            height: 20px;
            font-size: 20px;
            color: #FFFFFF;
        }
    }
}

.b-video {
    .video-container {
        padding-top: 0;
    }

    .video-container {
        padding-top: 0px;
        @media only screen and (min-width: 1300px) {
            padding-bottom: 46%;
        }
    }
}

.diapo-home {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-top: 57px;
    @media only screen and (max-width: 1023px) {
        padding-top: 0;
    }

    .swiper-slide {
        width: 100%;
        position: relative;

        img {
            display: block;
            width: calc(100% - 60px);
            margin: 0 auto;
            height: auto;
            @media only screen and (max-width: 600px) {
                width: calc(100% - 30px);
            }
        }

        .infos {
            position: absolute;
            width: 50%;
            left: 0;
            bottom: 4%;
            background-color: $blue;
            box-shadow: 0 2px 12px 0 rgba(10, 39, 87, 0.82);
            padding: 30px 20px 30px 8%;
            color: $white;

            h2 {
                @extend .titre2;
                color: $white;
                font-size: 4.6rem;
                font-family: $bold;
                line-height: 1.13;
                letter-spacing: 0.3px;
                margin: 10px 0;

                &::before {
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            h3 {
                font-size: 1.8rem;
                line-height: 1.67;
                letter-spacing: 0.1px;
                color: $color-second;
                padding-bottom: 30px;
            }

            @media only screen and (max-width: 1023px) {
                position: relative;
                width: auto;
                margin: 0 30px;
                top: inherit;
                left: inherit;
                transform: none;
                h2 {
                    font-size: 5.0rem;
                    margin: 10px 0;
                }
                h3 {
                    font-size: 2.0rem;
                }
            }
            @media only screen and (max-width: 600px) {
                padding: 10px;
                margin: 0 15px;
                h2 {
                    font-size: 3.0rem;

                    &::before {
                        display: none;
                    }
                }
                h3 {
                    font-size: 1.5rem;
                }
            }
        }
    }

    .pager {
        position: absolute;
        background-color: $white;
        right: 30px;
        bottom: 4%;
        padding: 1.5%;

        .page-prev, .page-next {
            position: absolute;
            color: $color-second;
            cursor: pointer;
            background: none;
            border: none;
            padding: 0;
        }

        .page-prev {
            bottom: 10%;
            left: 15%;
        }

        .page-next {
            top: 10%;
            right: 15%;
        }

        li {
            display: inline-block;
            vertical-align: middle;
            color: $color-second;
            font-size: 2.5rem;
            padding: 0 5px;
            cursor: pointer;

            &.actif {
                color: $color-main;
            }

            &:nth-of-type(2) {
                position: relative;

                &::before, &::after {
                    content: '';
                    position: absolute;
                    left: -5px;
                    top: 50%;
                    width: 1px;
                    height: 12px;
                    background-color: $color-main;
                    transform: translateY(-50%);
                }

                &::after {
                    left: inherit;
                    right: -5px;
                }
            }
        }

        @media only screen and (max-width: 1023px) {
            position: relative;
            right: inherit;
            bottom: inherit;
            text-align: center;
            i {
                top: 50%;
                transform: translateY(-50%);

                &.icon-left {
                    bottom: inherit;
                    top: 50%;
                }
            }
        }
    }
}

.b-map {
    & > * {
        position: relative;
        z-index: 10;
    }

    h2 {
        margin-left: 16%;
        margin-bottom: -7px;
        z-index: 20;
    }

    a {
        display: block;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    span.link {
        padding: 15px 0;
        display: block;
        text-align: center;
        font-size: 1.4rem;
        font-family: $bold;
        color: $color-second;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 10%;
            top: 50%;
            transform: translateY(-50%);
            height: 1px;
            background-color: $color-second;
            width: 34%;
        }
    }
}

.show-cmplz {
    cursor: pointer;
    font-weight: bold;
    width: fit-content;
}
