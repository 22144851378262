
/*******************  NORMALIZE ***********************/
html {
  margin: 0;
  padding: 0;
  font: normal 10px $regular, Arial, Verdana, Helvetica, sans-serif;
  color: $defaultTexteColor;
  text-size-adjust: 100%;
}

body {
  font-size: $defaultFontSize;
  font-family: $regular;
  font-weight: normal;
  margin: 0;
  padding: 0;
  background-color: $defaultBgcolor;
  width: 100%;
  color: $defaultTexteColor;
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-family: $bold;
}

h1, h2, h3, h4, h5, h6, p, ul, ol, li, a, span, strong, em, u, small, div, table, tbody, tr, td, form, input, select, textarea, fieldset, label, option {
  margin: 0;
  padding: 0;
  font-size: 1em;
  text-decoration: none;
  box-sizing: border-box;
}
u {
  text-decoration: underline;
}

b, strong {
  font-weight: normal;
  font-family: $bold;
}

a {
  color: $defaultLinkColor;
  cursor: pointer;
  transition: all 0.2s ease;

  &:focus,
  &:hover
  &:active {
    outline: 0;
  }
}
figure {
  overflow: hidden;
  margin: 0;
  padding: 0;
}
img {
  margin: 0;
  padding: 0;
  border: none;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  &.imgLeft {
    float: left;
    margin: 0 5px 5px 0;
  }
  &.imgRight {
    float: right;
    margin: 0 5px 5px 0;
  }
}

textarea {
  resize: vertical;
}

input[type=submit] {
  cursor: pointer;
}

select, input, textarea {
  font-family: $regular;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

hr {
  display: block;
  height: 2px;
  background-color: $grey;
  border: 0;
  margin: 14% 0;
  clear: both;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
  box-sizing :border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

.clearfix:after {
  content: " ";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}




/*******************  HELPER CLASSES ******************/

/*
 * Hide visually and from screen readers
 */

.hidden {
  display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.visually-hidden, .element-hidden, .element-invisible {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

body.preload * {
  transition: none !important;
}

svg {
    display: block;
}
