.onoffswitch {
  position: relative;
  width: 21px;
  user-select: none;
  display: block;
  float: right;
  margin-top: 4px;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 11px;
  padding: 0;
  line-height: 11px;
  border-radius: 11px;
  background-color: $white;
  transition: background-color 0.3s ease-in;
}

.onoffswitch-label:before {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  margin: 0px;
  background: #9b9b9b;
  position: absolute;
  top: 1px;
  right: 10px;
  border-radius: 9px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label {
  background-color: $white;
}

.onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label:before {
  border-color: $white;
}

.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  right: 1px;
  background: $color-main;
}

.b-map {
  position: relative;
  z-index: 20;
  padding: 40px 0 100px 0;
  .titre, ul {
    font-size: 1.2rem;
    font-family: $bold;
    margin: 0 16.667%;
  }
  ul {
    font-family: $italic;
    color: $white;
    font-size: 0;
    li {
      display: inline-block;
      vertical-align: top;
      font-size: 1.2rem;
      width: 25%;
      background-color: $color-main;
      padding: 1% 1% 1% 1.5%;
      &:nth-of-type(1) {
        background-color: $color-third;
        .onoffswitch-checkbox:checked + .onoffswitch-label:before {
          background: $color-third;
        }
      }
      &:nth-of-type(2) {
        background-color: $color-second;
        .onoffswitch-checkbox:checked + .onoffswitch-label:before {
          background: $color-second;
        }
      }
    }
  }
  #zoomin, #zoomout {
    bottom: 160px;
    right: 200px;
    text-align: center;
    position: absolute;
    z-index: 100;
    font-size: 2.0rem;
    padding: 5px 15px;
    font-family: $bold;
    background-color: $white;
    color: rgba($black, 0.5);
    box-shadow: inset 0 1px 6px 0 rgba(10, 39, 87, 0.39);
    cursor: pointer;
  }
  #zoomin {
    right: 150px;
    padding: 5px 10px;
  }
  .f-filtres {

  }
}

#map {
  height: 400px;
  width: 100%;
  .popup {
    color: $color-main;

    div {
      font-family: $bold;
      font-size: 1.0rem;
      p {
        line-height: normal;
      }

    }
    h2 {
      font-size: 1.4rem;
      text-decoration: underline;
      padding: 0 0 15px 0;
      margin: 0;
      color: $color-main;
      &::before {
        display: none;
      }
    }
    &.type1 {
      h2 {
        color: $color-third;
      }
    }
    &.type2 {
      h2 {
        color: $color-second;
      }
    }
  }

}